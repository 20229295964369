import React from 'react';
import { graphql } from 'gatsby';

import ContentPage from '~/components/Content/Page';
import Layout from '~/components/Layout';
import Metadata from '~/components/Metadata';

const StaticPageTemplate = React.forwardRef(({ data }, ref) => {
  const { page } = data;

  return (
    <Layout transparentNav={page.transparentNav} navColor={page.navColor}>
      <Metadata />
      <ContentPage page={data.page} ref={ref} />
    </Layout>
  );
});

export const pageQuery = graphql`
  query StaticPage($slug: String!, $locale: String) {
    page: contentfulPage(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      ...ContentPageFragment
    }
  }
`;

export default StaticPageTemplate;
